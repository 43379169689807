@use "../../../../styles/helpers";

.container {
  padding: .5rem 1rem;

  border-top: 1px solid color-mix(in srgb, var(--white, #FFF), transparent 90%);

  display: flex;
  flex-direction: row;

  gap: .5rem;

  @include helpers.clickable;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  &.up {
    background-color: color-mix(in srgb, var(--green, #40BC54), transparent 80%);
  }

  &.down {
    background-color: color-mix(in srgb, var(--red, #FF5252), transparent 80%);
  }
}

.content {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .skeleton {
    width: 8rem;
  }
}

.cause {
  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.createdAt {
  color: var(--gray-500, #737780);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.balances {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  .skeleton {
    height: 20px;
    width: 4rem;
  }
}

.amount {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  &.up {
    color: var(--green, #40BC54);
  }

  &.down {
    color: var(--red, #FF5252);
  }
}

.balance {
  color: var(--gray-500, #737780);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
