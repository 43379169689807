@use "../../../styles/theme";

.switch {
  position: relative;
  display: inline-block;

  width: 36px;
  height: 20px;

  user-select: none;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 34px;

  background-color: lighten(theme.$surface-color, 20);
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;

  border-radius: 50%;

  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: theme.$primary-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px theme.$primary-color;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.disabled {}

.switch.disabled .slider {
  background-color: lighten(theme.$surface-color, 40);
}
