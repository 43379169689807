.inner {
  width: .75rem;
  height: .75rem;
  border-radius: 100%;
  background-color: var(--blue, #3772FF);
  box-sizing: border-box;

  opacity: 0;
  transition: opacity .15s ease-out;
}

.content {
  width: 1.5rem;
  height: 1.5rem;

  box-sizing: border-box;

  border-radius: 100%;
  border: 2.5px solid var(--gray-300, #545659);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all .15s ease-out;
  user-select: none;
  cursor: pointer;

  &.selected {
    border: 2.5px solid var(--blue, #3772FF);
  }

  &.selected .inner {
    opacity: 1;
  }
}