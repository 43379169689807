:root {
  --gray-750: #ACB2BF;
  --gray-600: #8A8F99;
  --gray-500: #737780;
  --gray-300: #545659;
  --gray-250: #393B40;
  --gray-150: #222326;
  --gray-40: rgba(255, 255, 255, 0.40);
  --gray-secondary: #AAADB2;

  --green: #40bc54;
  --red: #FF5252;
  --blue: #3772FF;
  --yellow: #FFA800;
  --dark-yellow: #DD9200;
  --white: #FFF;
  --black: #000;

  --tooltip-bg: rgba(69, 71, 77, 0.85);
  --text-highlight: #9BF;

  --bg: #090A0E;
  --bg-1: #1C1D1F;
  --bottom-sheet-bg: rgba(69, 71, 77, 0.80);
}
