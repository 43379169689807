.item {
  padding: .75rem 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;

  background: var(--gray-150, #222326);

  &:not(:last-child) {
    border-bottom: 1px solid color-mix(in srgb, var(--white, #FFF) 10%, transparent);
  }

  &:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.item.tree {
  flex-direction: column;
  gap: 0;
}

.name {
  min-width: calc((100% - 3rem) / 3);

  color: var(--gray-750, #ACB2BF);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.val {
  display: block;

  flex: 1;

  color: var(--white, #FFF);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  word-wrap: anywhere;

  * {
    float: right;
  }

  &.ellipsis {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  &.noValue {
    color: var(--gray-500, #737780);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
}

.vline {
  float: right;
}

.treeChunk {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.treeLine {
  width: 100%;
}

.treeItem {
  float: right;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;

  color: var(--white, #FFF);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

.you {
  color: var(--blue, #3772FF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
