$background-color: #080A0C;
$primary-color: #3772FF;
$secondary-color: #F7931A;
$text-color: #FCFCFD;
$surface-color: #232328;
$error-color: #EF466F;
$on-primary-color: rgba(0, 0, 0, 0.5);
$swiper-bullet: #8F92A1;
$swiper-bullet-active: #E4D7CF;
$balance-up: #45B26B;
$balance-down: #FC3044;
$progress: #F7931A;
$caption-color: lighten(#3F4044, 15);

$modal-background: #2F2F36;
$paragraph-color: rgba(255, 255, 255, 0.8);
