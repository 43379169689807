@use "../../../styles/theme";

.container {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  border-radius: 0.875rem;
  height: 1.75rem;
  min-height: 1.75rem;

  padding-left: 0.5rem;
  padding-right: 0.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: theme.$surface-color;

  overflow-x: auto;
}

.item {
  display: flex;
  flex-direction: row;

  white-space: nowrap;
}

.text {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.line {
  stroke: lighten(theme.$surface-color, 20);
}
