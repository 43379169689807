@use "../../../styles/theme";

.button {
  width: fit-content;

  border: 1px solid theme.$primary-color;
  border-radius: 10px;
  padding: 1rem 2rem;

  align-items: center;

  background-color: transparent;
  color: theme.$primary-color;
  font-weight: 700;

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}
