.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.overlay {
  position: absolute;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .5);

  z-index: 10;

  transition: all .2s ease-out;
}

.popup {
  position: absolute;

  transition: all .2s ease-out;
}
