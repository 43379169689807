@use "../../../styles/theme";

.card {
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  display: flex;
  flex-direction: column;
  padding: 0.625rem 1rem;
}

.item {
  margin-bottom: 0.75rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.itemLabel,
.itemValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.balanceUp {
  color: theme.$balance-up;
}

.balanceDown {
  color: theme.$balance-down;
}
