.container {
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;
}

.content {
  max-width: 100%;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
}

.main {
  padding: 1rem 1rem 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}
