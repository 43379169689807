.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: .5rem;
}

.asset, .money {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 6px;
}

.slash, .money span, .asset span {
  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}
