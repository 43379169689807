.container {
  position: relative;

  width: 100%;

  svg {
    width: 100%;

    mask-image: linear-gradient(#FFF 0%, #FFF 70%, rgba(255, 255, 255, 0.00) 100%);;
  }
}

.heading {
  position: absolute;
  bottom: 35%;
  left: 0;
  right: 0;

  padding-left: 1rem;
  padding-right: 1rem;

  color: var(--white, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Heading 2/Bold */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 114.286% */

  user-select: none;
}