@use "../../../styles/theme";

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  color: theme.$text-color;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  text-align: center;
}

.line {
  height: 1px;
  width: 100%;
  max-width: 7rem;
  background-color: theme.$primary-color;
}
