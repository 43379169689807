@use "../../../styles/theme";

.container {
  border-radius: 0.75rem;
  padding: 1rem 1rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: lighten(theme.$surface-color, 5%);

  svg {
    fill: darken(theme.$text-color, 20);
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0 0 2rem;

    color: darken(theme.$text-color, 20);
    text-align: center;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  border-radius: 8px;

  min-width: 49%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  text-align: center;

  background-color: lighten(theme.$surface-color, 10%);

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }

  &.primary {
    background-color: theme.$primary-color;
  }

  &.full {
    min-width: 100%;
  }
}
