@use "../../../styles/theme";

.firstRow {
  padding-left: 1rem;
  padding-right: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    flex: 1;
  }

  .cancel {
    margin-left: 1rem;
    margin-right: 0.25rem;
    color: theme.$primary-color;

    &:hover {
      opacity: .7;
    }

    &:active {
      opacity: .5;
    }
  }
}

.content {
  margin-top: 1rem;

  flex: 1;

  overflow-y: auto;
}
