.container {
  overflow-y: auto;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4.875rem;

  display: flex;
  flex-direction: column;
}
