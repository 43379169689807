@use "theme";

.select__control {
  background-color: theme.$surface-color !important;
  border: 0 !important;
  border-radius: 12px !important;

  &.select__control--is-focused {
    border: 1px solid theme.$text-color !important;
    box-shadow: none !important;
  }
}

.select__single-value {
  color: theme.$text-color !important;
}

.select__indicator-separator {
  display: none;
}

.select__menu {
  background-color: theme.$surface-color !important;
  color: theme.$text-color !important;
  border-radius: 12px !important;
}

.select__menu-list {
  padding: 0 !important;
}

.select__option {
  &:first-child {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  &:last-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  &.select__control--is-selected {
    background-color: theme.$primary-color !important;
  }

  &.select__option--is-focused {
    background-color: lighten(theme.$primary-color, 5%) !important;
  }
}
