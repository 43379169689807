.block {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: .75rem;

  &.error .input {
    border: 1px solid var(--red, #FF5252);
  }
}

.input {
  border: 1px solid var(--gray-250, #393B40);
  border-radius: 10px;

  width: min(2.75rem, (100% - 6rem - .75rem * 5) / 6);
  aspect-ratio: 0.7875;

  text-align: center;

  color: var(--white, #FFF);
  background-color: transparent;
  outline: 0;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  transition: all .2s ease-out;

  &:focus, &:focus-visible {
    border: 1px solid var(--blue, #3772FF);
  }
}

.errorLabel {
  color: var(--red, #FF5252);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
