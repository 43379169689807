@use "../../../../styles/desktopcompat";

.container {
  display: flex;
  flex-direction: row;
  gap: .25rem;
}

.tooltip {
  padding: .5rem .75rem;

  max-width: 80vw;
  z-index: 999;

  border-radius: 10px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  --rt-color-dark: var(--tooltip-bg, rgba(69, 71, 77, 0.85));
  --rt-color-white: var(--white, #FFF);
  --rt-color-error: var(--red, #FF5252);
  --rt-color-success: var(--blue, #3772FF);
  --rt-color-warning: var(--yellow, #FFA800);
}

@include desktopcompat.compat-desktop() {
  .tooltip {
    max-width: 40vh;
  }
}
