.container {
  padding: 0.875rem 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.text {
  color: var(--gray-500, #737780);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}
