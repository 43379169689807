@use "../../../styles/theme";

.container {
  width: 100%;
  height: 3.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: theme.$surface-color;
}

.tab {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@supports(padding:max(0px)) {
  .container {
    padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}
