@use "../../../../styles/helpers";

.text {
  color: var(--blue, #3772FF);
  text-align: right;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 13px */

  @include helpers.clickable
}
