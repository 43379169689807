@use "../../../styles/desktopcompat";

.tooltip {
  max-width: 80vw;
  z-index: 1;
}

@include desktopcompat.compat-desktop() {
  .tooltip {
    max-width: 40vh;
  }
}
