.container {
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: .5rem;
}
