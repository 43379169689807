.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  * {
    text-align: center;
  }
}

.title {
  margin: 0 0 0.5rem;
  font-size: 24px;
  line-height: 32px;
}

.detail {
  margin: 0;
}
