@use "../../../../styles/theme";

.switch {
  margin: 2px;

  position: relative;
  display: inline-block;

  width: 48px;
  height: 28px;

  user-select: none;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 34px;

  background-color: lighten(theme.$surface-color, 20);
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;

  border-radius: 50%;

  background-color: var(--white, #FFF);
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--blue, #3772FF);
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.disabled {}

.switch.disabled .slider {
  background-color: lighten(theme.$surface-color, 40);
}
