@use "../../../styles/theme";

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  z-index: -200;
  background-color: theme.$background-color;
}
