@use "../../../styles/theme";

.card {
  margin-left: 1.25rem;
  margin-right: 1.25rem;

  height: calc(25vh - 2.5rem);

  padding: 1.25rem;

  display: flex;
  flex-direction: row;

  min-height: 0;
}

.chart {
  position: relative;

  min-width: 0;

  display: flex;
  flex: 1;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.legendHeader {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.legendContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.1rem;
}

.legendText {
  font-size: 15px;
  line-height: 21px;
  text-align: center;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.25rem;

  background-color: theme.$swiper-bullet;
}

.centerMain {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.balanceCrypto {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
}
