.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
}

.text {
  color: var(--gray-600, #8A8F99);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */

  &.success {
    color: var(--green, #40BC54);
  }

  &.error {
    color: var(--red, #FF5252);;
  }
}
