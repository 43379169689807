.first {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;

  display: flex;
  flex-direction: row;
}

.photo {
  margin-right: 1rem;

  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
}
