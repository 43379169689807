.container {
  padding: .5rem .375rem;

  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .375rem;

  color: var(--gray-750, #ACB2BF);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */

  background-color: var(--gray-150, #222326);

  user-select: none;
  cursor: pointer;

  transition: background-color .2s ease-out;

  &:hover {
    background-color: color-mix(in srgb, var(--gray-150, #222326), transparent 10%);
  }

  &:active {
    background-color: color-mix(in srgb, var(--gray-150, #222326), transparent 20%);
  }
}
