@use "../../../styles/theme";

.hline {
  height: 2.5rem;
  min-height: 2.5rem;
  width: 1px;

  background-color: theme.$text-color;
  margin-left: 3.5rem;
}

.item {
  margin-left: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemProfile {
  display: flex;
  flex-direction: column;
}

.itemPhoto {
  margin-left: 1rem;

  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}

.teamPhoto {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}

.nick {
  margin-left: 1rem;
  max-width: 7em;

  display: block;
  min-height: 14px;
  height: 14px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vline {
  width: 6.5rem;
  height: 0;

  border-top: 1px solid theme.$text-color;

  &.dashed {
    border-top-style: dashed;
  }
}

.mainPart {
  padding-top: 0.25rem;

  height: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.partIncome {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  margin-bottom: 0.25rem;
}

.depth {
  margin-top: 0.25rem;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: darken(theme.$text-color, 20);
}
