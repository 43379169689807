.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.card {
  border-radius: 12px;
  border: 2px solid var(--bg-1, #1C1D1F);

  padding: calc(1rem - 2px);

  min-height: 44px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .75rem;

  background: var(--bg-1, #1C1D1F);

  transition: border .2s ease-out;
  cursor: pointer;
  user-select: none;

  &.selected {
    border: 2px solid var(--blue, #3772FF);
  }

  .content {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
  }

  .header {
    color: var(--white, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .desc {
    color: var(--gray-secondary, #AAADB2);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .price {
    color: var(--white, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}