.container {
  border-top: 1px solid color-mix(in srgb, var(--white, #FFF), transparent 90%);

  padding: .5rem 1rem;

  width: calc(100% - 2rem);
  height: 2.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: .5rem;

  color: var(--gray-500, #737780);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
