@use "../../../styles/theme";

.button {
  position: relative;
  width: 100%;

  border: 0;
  padding: 1rem;

  align-items: center;

  background-color: theme.$primary-color;
  color: theme.$text-color;
  font-weight: 700;

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }

  &.error {
    background-color: theme.$error-color;
  }

  &.warning {
    background-color: theme.$secondary-color;
  }
}

.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: theme.$on-primary-color;
}
