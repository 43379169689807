.content {
  display: flex;
  flex: 1;
  height: 100%;
}

.main {
  padding: 1.5rem;

  display: flex;
  flex: 1;

  min-height: 0;
}
