.container {
  padding: .75rem;
  border-radius: 16px;
  background: var(--gray-150, #222326);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  user-select: none;
}

.texts {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: .75rem;
  justify-content: center;
}

.name {
  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
}

.desc {
  color: var(--gray-750, #ACB2BF);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 142.857% */
}
