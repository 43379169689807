.container {
  padding: 1rem;

  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: var(--gray-150, #222326);
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
}

.label {
  color: var(--gray-750, #ACB2BF);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.balance {
  color: #FFF;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 128% */

  .currency {
    font-weight: 300;
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.buttons {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: .5rem;

  & > * {
    flex: 1;
  }
}
