.container {
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1rem 1.5rem 2rem;
}

.label {
  color: var(--gray-500, #737780);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

.item {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .value {
    color: var(--white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .nf {
    opacity: .8;
  }
}

.split {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  & > * {
    width: calc(50% - 1.5rem / 2);
  }
}

.permissions, .ips {
  display: flex;
  flex-direction: column;

  & > span {
    margin-bottom: .5rem;
  }
}

.permission {
  border-top: 1px solid color-mix(in srgb, var(--white, #FFF), transparent 90%);

  padding-top: .5rem;
  padding-bottom: .5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;

  .name {
    flex: 1;

    color: var(--gray-750, #ACB2BF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */

    &.enabled {
      color: var(--white, #FFF);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .adviceerror, .advicewarning {
    padding-left: 6px;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }

  .adviceerror {
    color: var(--red, #FF5252);
  }

  .advicewarning {
    color: var(--yellow, #FFA800);
  }
}

.ip {
  border-top: 1px solid color-mix(in srgb, var(--white, #FFF), transparent 90%);

  padding-top: .5rem;
  padding-bottom: .5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;

  .value {
    flex: 1;

    color: var(--white, #FFF);
    font-family: "Roboto Mono", monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */

    &.error {
      color: var(--red, #FF5252);
      filter: brightness(75%);
    }

    &.warning {
      color: var(--yellow, #FFA800);
      filter: brightness(75%);
    }
  }

  .adviceerror, .advicewarning {
    padding-left: 6px;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }

  .adviceerror {
    color: var(--red, #FF5252);
  }

  .advicewarning {
    color: var(--yellow, #FFA800);
  }
}
