.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: text;
}

.input {
  flex: 1;
  color-scheme: dark;

  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
  }
}
