.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.logo {
  position: relative;
}

.label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.description {
  color: var(--white, #FFF);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}