.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  z-index: 100;
}

.content {
  padding: .75rem;

  min-width: 16rem;
  width: 65%;
  box-sizing: content-box;

  display: flex;
  flex-direction: column;
  gap: .75rem;

  border-radius: 12px;
  background: var(--blue, #3772FF);
}

.first {
  display: flex;
  flex-direction: row;
  gap: .5rem;
}

.text {
  flex: 1;

  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.second {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}

.button {
  border-radius: 8px;

  padding: 6px 1rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */

  cursor: pointer;
  user-select: none;
  transition: background-color .2s ease-out, opacity .2s ease-out;

  background-color: color-mix(in srgb, var(--white, #FFF), transparent 90%);

  &:hover {
    background-color: color-mix(in srgb, var(--white, #FFF), transparent 85%);
  }

  &:active {
    background-color: color-mix(in srgb, var(--white, #FFF), transparent 78%);
  }

  &.disabled {
    opacity: 0.4;

    cursor: default;
  }
}

.pos {
  color: color-mix(in srgb, var(--white, #FFF), transparent 50%);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.currentPos {
  color: #FFF;
}
