@use "../../../styles/theme";

.container {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;

    margin-bottom: 0.75rem;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: darken(theme.$text-color, 20);
    text-transform: uppercase;
  }

  * {
    flex-direction: row;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  .passwordEye {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    bottom: 0.75rem;

    padding-left: 0.25rem;
  }

  .copy {
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    bottom: 0.75rem;

    padding-left: 0.25rem;
  }

  .icon {
    position: absolute;
    left: 1rem;
    top: 0.7rem;
    bottom: 0.7rem;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    min-height: 1.5rem;

    border: 0;
    border-radius: 12px;

    padding: 0.75rem 1rem;

    box-shadow: 0 24px 24px -16px rgba(15, 15, 15, 0.2);
    background-color: theme.$surface-color;
    color: theme.$text-color;

    &:focus {
      border: 1px solid theme.$text-color;
    }

    &.withIcon {
      padding-left: 3rem;
    }

    &.withRightIcon {
      padding-right: 3rem;
    }

    //noinspection CssNoGenericFontName
    &.fakePassword {
      font-family: text-security-disc;
      -webkit-text-security: disc;
    }
  }
}

.sublabel {
  margin-top: 0.375rem;
  margin-bottom: 0;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: darken(theme.$text-color, 35);
}

.error {
  color: theme.$error-color;
}
