.content {
  cursor: text;
}

.currency {
  padding-left: 5px;

  color: var(--gray-40, rgba(255, 255, 255, 0.40));
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
