@use "./styles/normalize";
@use "./styles/theme";
@use "./styles/helpers";
@use "./styles/fonts";
@use "./styles/swiper";
@use "./styles/select";
@use "./styles/bottomsheet";
@use "./styles/colors";
@use "./styles/header";
@use "./styles/desktopcompat";
@use "./styles/scrollbar";

* {
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: theme.$background-color;
  color: theme.$text-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}
