.main {
  padding-bottom: .5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.title {
  color: var(--white, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.message {
  color: var(--gray-secondary, #AAADB2);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}