@use "../../../styles/theme";

.card {
  padding: 0.5rem 1rem 0.75rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: darken(theme.$text-color, 40);

  text-align: center;
}

.value {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  text-align: center;
}
