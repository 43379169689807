.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .75rem;

  user-select: none;

  color: var(--white, #FFF);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: .5rem;

  & > * {
    height: calc(2rem - .75rem * 2);
    padding: .75rem 1rem;
    flex: 1;
    border-radius: 111px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;

  padding-bottom: 14px;

  & > * {
    flex: 1;
    max-width: calc(50% - .5rem);

    input {
      max-width: 100%;
    }
  }
}
