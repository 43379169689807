.container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
}

.label {
  color: var(--gray-750, #ACB2BF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.number {
  color: var(--white, #FFF);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

