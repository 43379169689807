.container {
  padding: .5rem;
}

.content {
  border-radius: 16px;
  background: var(--bottom-sheet-bg, rgba(69, 71, 77, 0.80));
  backdrop-filter: blur(40px);

  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;

  & > * {
    flex: 1;
  }
}
