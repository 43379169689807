.text {
  margin: 1.25rem 0 0;

  text-align: center;

  font-size: 18px;
  line-height: 28px;
  font-weight: 300;

  user-select: none;
}
