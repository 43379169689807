.container {
  display: flex;

  position: relative;
}

.menu {
  position: absolute;
  top: calc(100% + 0.5rem);

  border-radius: 12px;

  z-index: 100;
  opacity: 0;
  user-select: none;
  pointer-events: none;

  background-color: var(--gray-250, #393B40);
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.25);

  transition: all .2s ease-out;

  &.open {
    opacity: 1;
    pointer-events: initial;
  }

  &.invertX {
    right: 0;
  }
}

.item {
  padding: 0.75rem;

  min-width: 6rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.5rem;

  cursor: pointer;
  background-color: transparent;
  transition: background-color .2s ease-out;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid color-mix(in srgb, var(--white, #FFF), transparent 90%);
  }

  &:hover {
    background-color: color-mix(in srgb, var(--white, #FFF), transparent 80%);
  }

  &:active {
    background-color: color-mix(in srgb, var(--white, #FFF), transparent 70%);
  }

  .text {
    flex: 1;

    color: var(--white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    white-space: nowrap;

    &.green {
      color: var(--green, rgb(64, 188, 84));
    }

    &.red {
      color: var(--red, rgb(255, 82, 82));
    }
  }
}

.roundContainer {
  width: 1rem;
  height: 1rem;
  padding: 0.25rem;

  border-radius: 111px;

  &.green {
    background: color-mix(in srgb, var(--green, rgb(64, 188, 84)), transparent 80%);
  }

  &.red {
    background: color-mix(in srgb, var(--red, rgb(255, 82, 82)), transparent 80%);
  }

  &.default {
    background: color-mix(in srgb, var(--white, rgb(255, 255, 255)), transparent 90%);
  }
}
