@use "../../../styles/theme";

.container {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: -100;

  width: 100vw;
  height: 100vh;

  object-fit: cover;
}

.content {
  position: relative;

  height: 97vh;
  width: 50vh;

  border-radius: 8px;

  background-color: rgba(theme.$background-color, .9);
}
