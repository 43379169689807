.card {
  height: calc(19vh - 2.25rem);

  padding: 1.625rem 1.5rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  margin: 0.5rem 0 0;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-height: 706px) {
  .card {
    padding-top: 0;
  }
}
