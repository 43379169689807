@use "../../../styles/theme";

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  z-index: 100;
}

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 101;
}

.modal {
  border-radius: 0.75rem;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: theme.$modal-background;

  user-select: none;
}

.banner {
  margin-bottom: 1.5rem;

  width: 20.5rem;
  height: 7.5rem;
}

.title {
  margin-bottom: 0.5rem;
  margin-top: 0;

  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;

  text-align: center;
}

.text {
  margin: 0;

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;

  text-align: center;

  color: theme.$paragraph-color;
}

.button {
  margin-top: 2rem;

  border-radius: 10px;

  width: 100%;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  text-align: center;

  background-color: lighten(theme.$surface-color, 15%);

  cursor: pointer;

  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}
