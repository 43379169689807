.form {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-top: 1.5rem;
}

.content {
  display: flex;
  flex: 1;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;

  &.scroll {
    overflow: auto;
  }
}
