.container {
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text {
  color: var(--white, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Text/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.content {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: .5rem;
}