.container {
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  & > * {
    width: calc((100% - 1.5rem) / 2);
    object-fit: contain;
  }

  img {
    border-radius: 10px;
    image-rendering: crisp-edges;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
