.qrContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  canvas {
    width: 100%;
    height: 100%;
  }
}
