.badge {
  width: fit-content;

  padding: 6px .5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */

  &.small {
    padding: 4px 6px;

    border-radius: 5px;
  }

  &.green {
    background-color: color-mix(in srgb, var(--green, #40BC54), transparent 80%);
    color: var(--green, #40BC54);
  }

  &.red {
    background-color: color-mix(in srgb, var(--red, #FF5252), transparent 80%);
    color: var(--red, #FF5252);
  }

  &.yellow {
    background-color: color-mix(in srgb, var(--yellow, #FFA800), transparent 80%);
    color: var(--yellow, #FFA800);
  }

  &.yellowBg {
    background-color: var(--yellow, #FFA800);
    color: var(--black, #000);
  }

  &.redBg {
    background-color: var(--yellow, #FFA800);
    color: var(--black, #000);
  }
}
