.container {
  border-radius: 10px;

  padding: .75rem 1rem;

  display: flex;
  flex-direction: row;
  gap: .5rem;

  background: var(--white, #FFF);

  cursor: pointer;
  user-select: none;
  text-decoration: none;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.get {
  color: var(--black, #000);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 10px */
  text-transform: uppercase;
}

.name {
  color: var(--black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
}
