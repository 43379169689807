@use "../../../styles/theme";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textContainer {
  width: calc(80%);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  margin: 0;

  width: calc(100%);
  display: inline-block;
  overflow: hidden;

  font-weight: 500;
  font-size: 26px;
  line-height: 40px;

  text-overflow: ellipsis;
}

.id {
  margin: 0;

  font-style: italic;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;

  color: darken(theme.$text-color, 20);
}

.imageContainer {
  position: relative;

  margin-right: 0.5rem;

  width: 4rem;
  height: 4rem;

  .image {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background-color: theme.$surface-color;
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.4);
  }

  .loader {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

.edit {
  margin-left: 1rem;

  height: 100%;

  padding-top: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
