.container {
  border-radius: 12px;

  padding: .5rem;

  display: flex;
  flex-direction: row;
  gap: .5rem;

  background: var(--blue, #3772FF);
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.25);

  &.warning {
    background: var(--dark-yellow, #DD9200);
  }

  &.error {
    background-color: var(--red, #FF5252);
  }
}

.text {
  align-self: center;

  flex: 1;

  color: var(--white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
