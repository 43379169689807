@use "../../../styles/theme";

.container {
  padding: 1rem;

  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.photo {
  margin-right: 0.75rem;

  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;

  flex: 1;
}

.lane {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: darken(theme.$text-color, 20);

  margin-bottom: 0.25rem;
}

.name {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  text-overflow: ellipsis;
}

.income {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.firstLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
