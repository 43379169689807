.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: text;
}

.icon {
  padding-left: .5rem;
}

.text {
  max-width: calc(100% - 24px - .5rem);

  flex: 1;

  word-wrap: break-word;
  display: inline-block;
}
