.container {
  display: flex;
  flex-direction: row;

  &.stretch {
    min-width: 100%;

    & > * {
      flex: 1;
    }
  }
}

