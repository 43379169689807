@use "../../../styles/theme";

.container {
  height: 100%;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 8rem;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 768px) {
  .logo {
    transform: translate(-50%, -100%);
  }
}

.content {
  width: 100%;
  height: calc(100% - 1rem);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: theme.$text-color;
    text-decoration: none;
  }
}
