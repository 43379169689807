.container {
  position: relative;
}

.item {
  position: absolute;
  left: 0;
  right: 0;

  height: 0;
  transition: visibility 300ms linear, height 300ms linear;
  animation: carousel 300ms;
}

.hide {
  animation: carousel-hide 300ms forwards;
}

@keyframes carousel {
  0% {
    opacity: 0;
    bottom: -36px;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes carousel-hide {
  0% {
    opacity: 1;
    bottom: 0;
  }

  100% {
    opacity: 0;
    bottom: 36px;
  }
}
