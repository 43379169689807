@use "../../../styles/theme";

.container {
  border-radius: 0.75rem;
  padding: 1rem 1rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: lighten(theme.$surface-color, 5%);
}

.header {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 0.75rem;

  font-weight: 500;

  span {
    min-width: 49%;
    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
}

.coinContainer {
  width: 49%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border-radius: 0.75rem;

  background-color: lighten(theme.$surface-color, 2%);
}

.coin {
  min-width: 49%;
  padding: 0.875rem 0.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  text-align: center;

  background-color: lighten(theme.$surface-color, 15%);

  color: theme.$text-color;

  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }

  &:last-child {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  &:first-child {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  &.selected {
    background-color: mix(theme.$primary-color, lighten(theme.$surface-color, 15%), 25%);
  }

  &.disabled {
    background: rgba(71, 71, 81, 0.5);

    span {
      opacity: 0.5;
      filter: blur(0.25rem);
    }

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 1;
    }
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

    line-height: 100%;
  }
}

.businessTag {
  margin-left: auto;
}

.closeButton {
  margin-top: 1.5rem;

  border-radius: 8px;

  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  text-align: center;

  background-color: lighten(theme.$surface-color, 15%);

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}
