.button {
  position: relative;

  padding: 1rem 2rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  color: var(--white, #FFF);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 80% */

  cursor: pointer;
  user-select: none;
  transition: background-color .2s ease-out;

  &.primary {
    background-color: var(--blue, #3772FF);

    &:hover {
      background-color: #2F61D9;
    }

    &:active {
      background-color: #2650B2;
    }
  }

  &.gray {
    background-color: var(--gray-250, #393B40);

    &:hover {
      background-color: darken(#393B40, 4%);
    }

    &:active {
      background-color: darken(#393B40, 8%);
    }
  }

  &.error {
    background-color: var(--red, #FF5252);

    &:hover {
      background-color: #D94646;
    }

    &:active {
      background-color: #B23939;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .6);
}
