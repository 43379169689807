.main {
  position: relative;

  height: 100%;

  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: calc(100% - 3rem);

  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--white, #FFF);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}

.desc {
  padding-top: 1rem;
  padding-bottom: 1.5rem;

  color: var(--gray-750, #ACB2BF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
