.container {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //flex-grow: 1;
}

.button {
  border-radius: 10px;

  padding: .75rem 1rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  flex-grow: 1;

  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */

  cursor: pointer;
  user-select: none;
  transition: background-color .2s ease-out, opacity .2s ease-out;

  &.loading {
    opacity: .4;
  }

  &.small {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  &.primary {
    background-color: var(--blue, #3772FF);

    &:hover {
      background-color: #2F61D9;
    }

    &:active {
      background-color: #2650B2;
    }

    &.disabled {
      background-color: var(--blue, #3772FF) !important;
      opacity: 0.4;

      cursor: default;
    }
  }

  &.default {
    background-color: color-mix(in srgb, var(--white, #FFF), transparent 90%);

    &:hover {
      background-color: color-mix(in srgb, var(--white, #FFF), transparent 80%);
    }

    &:active {
      background-color: color-mix(in srgb, var(--white, #FFF), transparent 70%);
    }

    &.disabled {
      background-color: color-mix(in srgb, var(--white, #FFF), transparent 90%);
      opacity: 0.4;
    }
  }

  &.error {
    background-color: var(--red, #FF5252);

    &:hover {
      background-color: #D94646;
    }

    &:active {
      background-color: #B23939;
    }

    &.disabled {
      background-color: var(--red, #FF5252);
      opacity: 0.4;

      cursor: default;
    }
  }
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}