@use "../../../styles/theme";

.container {
  padding: 3rem 1.5rem 1rem;

  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: auto;
}

.content {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  color: darken(theme.$text-color, 20);
  line-height: 1.5rem;
  text-align: center;
}

.to {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  text-align: center;

  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.375rem;
}

.header {
  text-align: center;

  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: darken(theme.$text-color, 20);
}

.text {
  text-align: center;

  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.profile {
  display: flex;
  flex-direction: column;

  .photo {
    margin-bottom: 0.75rem;

    width: 4rem;
    height: 4rem;
  }

  .name {
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.875rem;
    text-align: center;
  }
}
