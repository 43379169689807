.container {
  position: relative;
  box-sizing: border-box;

  width: 100%;

  padding: 1rem 1rem 1.25rem;

  border-radius: 1rem;

  display: flex;
  flex-direction: column;

  background: var(--gray-150, #222326);
}

.heading {
  color: var(--gray-750, #ACB2BF);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */

  margin-bottom: 1rem;

  user-select: none;
}

.chart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: .25rem;
}

.chartSkeleton {
  margin-bottom: .5rem;
}

.chartGroup {
  margin-bottom: .5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  span:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  span:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  span {
    height: .5rem;

    transition: height .3s ease-in-out;
  }

  &.expanded span {
    height: 1.5rem;
  }

  &.income span {
    background-color: var(--green, #40bc54);
  }

  &.cost span {
    background-color: var(--red, #FF5252);
  }
}

.emptyChartGroup {
  margin-bottom: .5rem;

  width: 100%;
  height: .5rem;
  background-color: var(--gray-250, #393B40);

  border-radius: 4px;
}

.totals {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  .income {
    color: var(--green, #40bc54);
  }

  .cost {
    color: var(--red, #FF5252);
  }
}

.noTx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;

  color: var(--gray-500, #737780);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}

.body {
  max-height: 0;

  overflow: hidden;
  transition: max-height .3s ease-in-out;

  &.expanded {
    max-height: 200px;
  }
}

.line {
  width: 100%;
  height: 1px;

  margin-top: 1rem;
  margin-bottom: 1rem;

  background-color: color-mix(in srgb, var(--white, #FFF), 90% transparent);
}

.list {
  padding-bottom: .5rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .group {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .income .icon {
    background-color: var(--green, #40bc54);
  }

  .income .amount {
    color: var(--green, #40bc54);
  }

  .cost .icon {
    background-color: var(--red, #FF5252);
  }

  .cost .amount {
    color: var(--red, #FF5252);
  }

  .item {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 6px;

    .icon {
      width: .625rem;
      height: .625rem;
      border-radius: .625rem;
    }

    .name {
      flex: 1;

      color: var(--white, #FFF);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }

    .amount {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }
  }
}

.button {
  position: absolute;
  bottom: -1rem;
  left: calc(50% - 1rem);

  border: 2px solid var(--bg, #090A0E);

  width: 2rem;
  height: 2rem;
  border-radius: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--gray-150, #222326);

  cursor: pointer;
  user-select: none;
  transition: background-color .2s ease-out;

  &.disabled {
    cursor: initial;
  }

  &:not(.disabled):hover {
    background-color: color-mix(in srgb, var(--gray-150, #222326), 10% white);
  }

  &:not(.disabled):active {
    background-color: color-mix(in srgb, var(--gray-150, #222326), 20% white);
  }

  svg {
    transition: all .2s ease-out;
  }

  &.expanded svg {
    transform: rotate(180deg);
  }
}
