.header {
  padding: .25rem .5rem;

  width: calc(100% - 1rem);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .25rem;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */

  &.yellow {
    background-color: var(--yellow, #FFA800);
    color: var(--black, #000);
  }

  &.primary {
    background-color: color-mix(in srgb, var(--blue, #3772FF), #000 30%);
    color: var(--white, #FFF);
  }

  &.red {
    background-color: color-mix(in srgb, var(--red, #FF5252), #000 30%);
    color: var(--white, #FFF);
  }
}
