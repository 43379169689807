@use "theme";
@use "desktopcompat";

:root {
  --rsbs-backdrop-bg: theme.$background-color;
  --rsbs-bg: theme.$background-color;
  --rsbs-handle-bg: theme.$surface-color;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 12px;
}

@include desktopcompat.compat-desktop() {
  [data-rsbs-overlay], [data-rsbs-root]:after {
    width: 50vh;
    left: calc((100vw - 50vh) / 2);
  }
}

.no-scroll [data-rsbs-scroll] {
  overflow: hidden !important;
}
