@use "../../../styles/theme";

.container {
  padding: 1rem 1rem 0;

  display: flex;
  flex-direction: row;

  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}

@media screen and (max-width: 338px) {
  .icon {
    display: none;
  }
}

.main {
  margin-left: 1rem;

  display: flex;
  flex-direction: column;
  flex: 1;
}

.topLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row {
  flex: 1;
  max-width: calc(85%);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  text-overflow:ellipsis;
  max-width: calc(80%);
  overflow:hidden;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.date {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: theme.$caption-color;
  white-space: nowrap;
}

.description {
  color: theme.$caption-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.nack {
  margin-left: 0.4rem;

  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;

  background-color: #3772FF;
}
