.container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.label {
  color: var(--gray-750, #ACB2BF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.imgContainer {
  width: calc(100% - 2rem);

  padding: 1rem;

  border-radius: .5rem;

  aspect-ratio: 1;
  background: var(--gray-150, #222326);
}

.img {
  width: 100%;
  height: 100%;
}
