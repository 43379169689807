@use "../../../styles/theme";

.container {
  border-radius: 0.75rem;
  padding-top: 0.75rem;

  display: flex;
  flex-direction: column;

  background-color: lighten(theme.$surface-color, 5%);
}

.bottomPad {
  height: 1.5rem;
}

.cancelPad {
  height: 0.5rem;
}

.card {
  position: relative;

  margin-left: 0.5rem;
  margin-right: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  background-color: lighten(theme.$surface-color, 20%);

  &:active {
    background-color: lighten(theme.$surface-color, 30%);
  }

  &.selected {
    background-color: mix(theme.$primary-color, lighten(theme.$surface-color, 20%), 25%);
  }

  &.selected:active {
    background-color: mix(theme.$primary-color, lighten(theme.$surface-color, 30%), 25%);
  }

  &:last-child {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  &:first-child {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
}

.cancel,
.item {
  color: theme.$text-color;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.item.destructible {
  color: theme.$error-color;
}

.icon {
  margin-right: 0.5rem;
}

.tag {
  position: absolute;
  right: 0.75rem;
}
