::-webkit-scrollbar {
  width: 8px;

  background: rgba(8, 10, 12, 0.80);
  backdrop-filter: blur(5px);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(69, 71, 77, 0.50);;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #737780;
  border-radius: 4px;

  transition: background-color .2s ease-out;

  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lighten(#737780, 10%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
  background: lighten(#737780, 20%);
}
