@use "../../../styles/theme";

.card {
  position: relative;

  margin-left: 1.25rem;
  margin-right: 1.25rem;

  display: flex;
  flex-direction: column;
  flex: 1;

  min-height: 0;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconContainer {
  padding: 0.5rem 1rem;

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}

.month {
  border-bottom: 2px solid theme.$primary-color;

  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.footer {
  display: flex;
  flex-direction: row;

  width: 100%;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footerText {
  margin-left: 1rem;

  width: 50%;

  font-size: 15px;
  line-height: 18px;
}

.noData {
  position: absolute;
  top: 50%;

  margin-top: -6px;

  width: 100%;

  text-align: center;
  color: darken(theme.$text-color, 20);
  font-size: 12px;
  line-height: 14px;

  user-select: none;
}

.chart {
  position: relative;

  min-width: 0;

  display: flex;
  flex: 1;
  min-height: 0;

  canvas {
    max-height: 100% !important;
  }
}
