.container {
  width: calc(100% - 2rem);
  padding: .5rem 1rem;

  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
}

.track {
  height: 2px;
  flex: 1;

  background-color: var(--gray-250, #393B40);

  transition: width .2s ease-out;
}

.head {
  height: 100%;

  background-color: var(--white, #FFF);

  transition: width .2s ease-out;
}

.text {
  color: var(--white, #FFF);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 13px */
}

.max {
  color: var(--gray-500, #737780);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
