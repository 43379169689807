.list {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
}

.text {
  color: var(--white, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}