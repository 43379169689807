.container {
  border-radius: 10px;

  padding: .75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  background-color: color-mix(in srgb, var(--yellow, #FFA800) 20%, transparent);
}

.text {
  color: var(--yellow, #FFA800);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
