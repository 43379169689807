@use "../../../styles/theme";

.container {
  position: relative;
}

.badge {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;

  padding: 0.5rem;
  border-radius: 1rem;

  background-color: theme.$secondary-color;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.image {
  object-fit: cover;
}
