@use "../../../styles/theme";

.card {
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: theme.$surface-color;
}

.clickable {
  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}
