@use "../../../styles/theme";

.card {
  margin-bottom: 1.5rem;

  padding: 0.75rem 0 1rem;
}

.tradePad {
  height: 0.75rem;
}

// first line

.firstLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;

  padding-left: 0.75rem;
  padding-right: 0.75rem;

  .pair {
    margin-right: 0.25rem;

    white-space: pre;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .status {
    white-space: pre;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    &.completed {
      color: lighten(theme.$surface-color, 20);
    }
  }

  .profitLabel {
    white-space: pre;
    color: lighten(theme.$surface-color, 20);
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .profitCard {
    padding: 6px 0.75rem;

    border-radius: 5px;

    background-color: lighten(theme.$surface-color, 10);

    white-space: pre;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: theme.$balance-up;
  }
}

@media screen and (max-width: 404px) {
  .profitLabel {
    display: none;
  }
}

// header
.header {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  display: flex;
  flex-direction: row;

  padding-right: 0.75rem;

  .item {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }

  .date {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    margin-left: 49px;
    width: 3rem;
    text-align: center;
  }

  .field {
    flex: 1;
    text-align: center;
  }

  .operation {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 0.5rem;
  }
}

@media screen and (max-width: 512px) {
  .header {
    .volume {
      display: none;
    }

    .eq {
      display: none;
    }

    .operation {
      width: 0;
    }
  }
}

// trade line

.tradeLine {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-right: 0.75rem;

  .tradeArrow {
    position: relative;
    height: 1.3rem;

    span {
      position: absolute;
      left: 0.7rem;
      top: 0.25rem;

      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .date {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 3rem;

    text-align: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: lighten(theme.$surface-color, 20);
  }

  .field {
    flex: 1;
    padding: 6px 0.75rem;

    border-radius: 5px;

    background-color: lighten(theme.$surface-color, 10);
    text-align: center;

    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .operation {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: lighten(theme.$surface-color, 20);
  }
}

@media screen and (max-width: 512px) {
  .tradeLine {
    .volume {
      display: none;
    }

    .eq {
      display: none;
    }

    .operation {
      font-size: 0;
    }
  }
}

// helpers

.flex {
  display: flex;
  flex: 1;
  min-width: 1rem;
}
