@use "../../../styles/theme";

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.25rem;

  display: flex;
  flex-direction: column;
}

.item {
  margin-top: 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  text-decoration: none;
  color: theme.$text-color;

  cursor: pointer;

  p {
    margin: 0;
    font-size: 18px;
    line-height: 20px;
  }

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}

.danger {
  color: theme.$error-color;
}
