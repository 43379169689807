@use "../../../../styles/helpers";

.container {
  border: 2px solid transparent;

  padding: 1rem;
  border-radius: 16px;
  background: var(--gray-150, #222326);

  display: flex;
  flex-direction: column;
  gap: .5rem;

  user-select: none;
  cursor: pointer;

  transition: border-color .2s ease-out;

  &.failed > * {
    opacity: .4;
  }

  &:hover, &:active {
    border-color: var(--blue, #3772FF);
  }
}

.name {
  opacity: 1 !important;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nameText {
  width: calc(90%);
  display: inline-block;
  overflow:hidden;
  white-space: nowrap;

  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-overflow: ellipsis;
}

.container.failed .nameText {
  opacity: .4;
}

.expires {
  display: flex;
  flex-direction: row;
  gap: .25rem;
  align-items: center;

  color: var(--gray-600, #8A8F99);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

.status {
  display: flex;
  flex-direction: row;
  gap: .25rem;
  align-items: center;

  color: var(--yellow, #FFA800);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */

  opacity: 1 !important;

  &.error {
    color: var(--red, #FF5252);
  }
}
