@use "../../../styles/theme";

.tag {
  align-self: center;

  width: fit-content;

  border: 0;
  border-radius: 10px;
  padding: 0.25rem 0.3125rem;

  align-items: center;

  background: linear-gradient(91.26deg, #70A2FF 0%, #F76E64 100%);
  color: theme.$text-color;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.625rem;

  text-transform: uppercase;
}
