@use "../../../styles/theme";
@use "../../../styles/desktopcompat";

.card {
  height: calc(19vh - 2.25rem);

  padding: 1.625rem 1.5rem 0;

  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
}

.upLine {
  margin-bottom: 0.75rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name {

  font-size: 12px;
  line-height: 20px;
}

.centerMain {
  margin-bottom: 20px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.small {
    margin-bottom: 0;
  }
}

.balanceCrypto {
  margin: 0;

  font-size: 14px;
  line-height: 20px;
}

.balanceUsd {
  margin-top: 0.75rem;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.balanceCost {
  margin-left: 0.25rem;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.balanceUp {
  color: theme.$balance-up;
}

.balanceDown {
  color: theme.$balance-down;
}

.balancePercent {
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
}

.mainRow {
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.row {
  padding-right: 2rem;

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 1rem;
  }
}

.rowRight {
  padding-left: 2rem;

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 1rem;
  }
}

.slash {
  font-size: 32px;
  font-weight: bold;
}

@mixin small-screen {
  .row {
    padding-right: 1rem;

    img {
      margin-left: 0.5rem;
      transform: scale(0.75);
    }
  }

  .rowRight {
    padding-left: 1rem;

    img {
      margin-right: 0.5rem;
      transform: scale(0.75);
    }
  }

  .slash {
    font-size: 24px;
    font-weight: bold;
  }
}

@media screen and (max-width: 1100px) {
  @include desktopcompat.compat-desktop() {
    @include small-screen;
  }
}

@media screen and (max-width: 480px) {
  @include small-screen;
}

@media screen and (max-height: 768px) {
  .mainRow {
    margin-top: 0.25rem;
  }
}
