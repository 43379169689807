.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  user-select: none;
}

.text {
  color: var(--gray-750, #ACB2BF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
