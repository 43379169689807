.container {
  position: absolute;

  top: 3.75rem;
  left: 2rem;
  right: 2rem;

  display: flex;
  flex-direction: column;
  gap: .5rem;

  z-index: 50;

  user-select: none;
}
