.container {
  position: relative;

  padding: .5rem 6px;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 6px;

  border-radius: 8px;
  background-color: var(--gray-150, #222326);

  transition: background-color .2s ease-out;

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--gray-250, #393B40);
  }

  &:active {
    background-color: lighten(#393B40, 10%);
  }
}

.text {
  color: var(--gray-750, #ACB2BF);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}

.applied {
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;

  width: .5rem;
  height: .5rem;

  border: 2px solid var(--bg, #090A0E);

  border-radius: 999px;

  opacity: 0;
  background-color: var(---blue, #3772FF);

  transition: opacity .2s ease-out;

  &.show {
    opacity: 1;
  }
}
