@use "../../../styles/theme";

.logo {
  margin-top: 6rem;
}

.content {
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;

  a {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: theme.$text-color;
    text-decoration: none;
  }
}

.container {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-content: center;
}

.text {
  margin-top: 6rem;

  padding-left: 1rem;
  padding-right: 1rem;

  font-size: 20px;
  text-align: center;
}

.caption {
  margin-top: 0.5rem;

  font-size: 16px;
  color: darken(theme.$text-color, 20);
}
