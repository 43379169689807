@use "../../../styles/theme";

.container {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.75rem;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: darken(theme.$text-color, 20);
    text-transform: uppercase;
  }

  * {
    flex-direction: row;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input {
  min-height: 1.5rem;

  border: 0;
  border-radius: 12px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.75rem 1rem;

  box-shadow: 0 24px 24px -16px rgba(15, 15, 15, 0.2);
  background-color: theme.$surface-color;
  color: theme.$text-color;

  &.caption {
    color: darken(theme.$text-color, 20);
  }

  &:hover {
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  margin-top: -2px;
}

.rightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  margin-right: 0.5rem;
}
