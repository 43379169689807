.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: text;
}

.input {
  flex: 1;

  &.hasIcon {
    max-width: calc(100% - 24px - .5rem);
  }

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
  }
}

.icon {
  padding-left: .5rem;
}
