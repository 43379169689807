.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profit {
  padding-top: 1rem;
  padding-bottom: 1rem;

  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */

  &.up {
    color: var(--green, #40BC54);
  }

  &.down {
    color: var(--red, #FF5252);
  }
}

.coin {
  font-weight: 300;
}

.time {
  color: var(--gray-600, #8A8F99);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  padding-bottom: 1rem;
}

.badge {
  padding: .25rem .5rem;

  border-radius: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: .25rem;

  color: var(--gray-750, #ACB2BF);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */

  background: color-mix(in srgb, var(--white, #FFF), transparent 90%);

  &.success {
    background: color-mix(in srgb, var(--green, #40bc54), transparent 80%);
    color: var(--green, #40BC54);
  }

  &.failure {
    background: color-mix(in srgb, var(--red, #FF5252), transparent 80%);
    color: var(--red, #FF5252);
  }
}
