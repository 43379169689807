@use "../../../../styles/helpers";

.icon {
  transition: fill .2s ease-out, stroke .2s ease-out;
}

.clickable {
  @include helpers.clickable;
}

$colors: (
        "Default": var(--gray-750, #ACB3BF),
        "Gray500": var(--gray-500, #737780),
        "Primary": var(--blue, #3772FF),
        "Yellow": var(--yellow, #FFA800),
        "Green": var(--green, #40BC54),
        "Red": var(--red, #FF5252),
        "White": var(--white, #FFF),
        "Black": var(--black, #000),
);

@each $name, $col in $colors {
  .col#{$name} {
    fill: #{$col};
    stroke: #{$col};
  }
}
