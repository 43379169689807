.root {
  .container {
    margin-top: .5rem;
    margin-bottom: .25rem;

    padding: calc(0.75rem - 1px);

    border-radius: 10px;
    border: 1px solid var(--gray-150, #222326);

    background: var(--gray-150, #222326);
    color: var(--white, #FFF);

    transition: border .2s ease-out;

    input, *[role=textbox], textarea {
      background: transparent;
      outline-width: 0;
      border: 0;
      padding: 0;

      width: auto;

      color: var(--white, #FFF);

      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &::placeholder {
        color: var(--gray-40, rgba(255, 255, 255, 0.40));
      }
    }
  }

  &.selected .container {
    border: 1px solid var(--blue, #3772FF);
  }

  &.error .container {
    border: 1px solid var(--red, #FF5252);
  }

  .label {
    color: var(--gray-750, #ACB2BF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .errorLabel {
    color: var(--red, #FF5252);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

